import React from "react";

const ProfileSettings = ({ name, setName, email, setEmail, phoneNumber, setPhoneNumber }) => (
  <div>
    {/* Name Input */}
    <div style={{ marginBottom: "10px" }}>
      <label style={{ display: "block", marginBottom: "5px", color: "black" }}>Name:</label>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="input settings-input"
        style={{
          width: "100%",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      />
    </div>

    {/* Email Input */}
    <div style={{ marginBottom: "10px" }}>
      <label style={{ display: "block", marginBottom: "5px", color: "black" }}>Email:</label>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="input settings-input"
        style={{
          width: "100%",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      />
    </div>

    {/* Phone Number Input */}
    <div style={{ marginBottom: "10px" }}>
      <label style={{ display: "block", marginBottom: "5px", color: "black" }}>Phone #:</label>
      <input
        type="text"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        className="input settings-input"
        style={{
          width: "100%",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      />
    </div>
  </div>
);

export default ProfileSettings;