import React, { useState } from "react";

const ResetPasswordPage = ({ setCurrentPage }) => {
  const [email, setEmail] = useState("");

  return (
    <div
      className="reset-password-container"
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "20px",
        maxWidth: "400px",
        margin: "20px auto",
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
      }}
    >
      <h2
        style={{
          color: "#000",
          marginBottom: "20px",
          fontWeight: "bold",
          textTransform: "uppercase",
          fontSize: "22px",
        }}
      >
        Reset Password:
      </h2>
      <p style={{ color: "#555", marginBottom: "20px" }}>
        Enter your email address, and we’ll send you instructions to reset your password.
      </p>
      <input
        type="email"
        placeholder="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{
          width: "100%",
          maxWidth: "300px",
          padding: "12px",
          borderRadius: "8px",
          border: "1px solid #ccc",
          marginBottom: "15px",
          fontSize: "14px",
        }}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          onClick={() => alert("Password reset instructions sent!")}
          style={{
            backgroundColor: "#007BFF",
            color: "white",
            padding: "10px 20px",
            borderRadius: "8px",
            border: "none",
            fontWeight: "bold",
            fontSize: "16px",
            cursor: "pointer",
            width: "45%",
          }}
        >
          Reset Password
        </button>
        <button
          onClick={() => setCurrentPage("settings")}
          style={{
            backgroundColor: "#FF5733",
            color: "white",
            padding: "10px 20px",
            borderRadius: "8px",
            border: "none",
            fontWeight: "bold",
            fontSize: "16px",
            cursor: "pointer",
            width: "45%",
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default ResetPasswordPage;