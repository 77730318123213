import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminSendAlerts.css";

const AdminSendAlerts = () => {
  const [alertType, setAlertType] = useState("general");
  const [alertMessage, setAlertMessage] = useState("");
  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 250;
  const navigate = useNavigate();

  const handleSendAlert = () => {
    if (alertMessage.trim()) {
      alert(`Alert sent (${alertType}): ${alertMessage}`);
      setAlertMessage("");
      setCharCount(0);
    } else {
      alert("Please enter a message before sending.");
    }
  };

  const handleBack = () => {
    navigate("/admin");
  };

  const handleInputChange = (e) => {
    const message = e.target.value;
    if (message.length <= maxCharLimit) {
      setAlertMessage(message);
      setCharCount(message.length);
    }
  };

  return (
    <div className="admin-send-alerts">
      <div className="header-box">
        <h1>PROTECTED CAMPUS</h1>
        <h3>Digital Blue Light</h3>
        <h3>Admin Send Alerts</h3>
      </div>

      <div className="alert-type-container">
        <h2>Choose the Type of Alert</h2>
        <div className="alert-type-selector">
          <label htmlFor="alert-type">Select Alert Type:</label>
          <select
            id="alert-type"
            value={alertType}
            onChange={(e) => setAlertType(e.target.value)}
          >
            <option value="Hold">Hold</option>
            <option value="Secure">Secure</option>
            <option value="Lockdown">Lockdown</option>
            <option value="Evacuate">Evacuate</option>
            <option value="Shelter">Shelter</option>  
            <option value="CUSTOM">CUSTOM</option> 
          </select>
        </div>
      </div>

      <div className="send-alerts-form">
        <textarea
          value={alertMessage}
          onChange={handleInputChange}
          placeholder="Type your alert message here..."
          rows="6"
        />
        <div className="char-counter">
          {charCount}/{maxCharLimit} characters
        </div>
        <button className="send-alerts-button" onClick={handleSendAlert}>
          Send Alert
        </button>
      </div>

      <button className="back-button" onClick={handleBack}>
        Back to Admin Menu
      </button>
    </div>
  );
};

export default AdminSendAlerts;