import React, { useState, useEffect } from "react";
import api from "../api"; // Ensure this path points to your api.js file

const AlertList = () => {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await api.get("/alerts/");
        setAlerts(response.data.alerts);
      } catch (error) {
        console.error("Error fetching alerts:", error);
      }
    };

    fetchAlerts();
  }, []);

  return (
    <div>
      <h2>Alerts</h2>
      <ul>
        {alerts.map((alert) => (
          <li key={alert.id}>
            {alert.message} - ({alert.latitude}, {alert.longitude})
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AlertList;