import React from "react";
import { useNavigate } from "react-router-dom";

const AdminDataAnalytics = () => {
  const navigate = useNavigate();

  const descriptiveData = [
    { label: "Monthly Alerts", value: "650" },
    { label: "Resolved Incidents", value: "540" },
    { label: "Average Active Users", value: "1,200" },
  ];

  const predictiveData = [
    { label: "Expected Alerts Next Month", value: "720" },
    { label: "Incident Escalations", value: "5%" },
    { label: "User Growth Rate", value: "3%" },
  ];

  const prescriptiveData = [
    { label: "Recommended Response Time", value: "2m 15s" },
    { label: "Staff Increase Needed", value: "10%" },
    { label: "Suggested Security Upgrades", value: "Cameras, Software" },
  ];

  const trendsData = [
    { label: "High Alert Locations", value: "Dorms, Gym" },
    { label: "Peak Alert Hours", value: "8 PM - 12 AM" },
    { label: "Most Common Incident Type", value: "Unauthorized Access" },
  ];

  const renderSection = (title, data, bgColor) => (
    <div style={{ marginBottom: "30px" }}>
      <h2 style={{ color: "#2774e0", textAlign: "center" }}>{title}</h2>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor: bgColor,
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h4 style={{ margin: "0 0 10px", fontSize: "1.2rem" }}>
              {item.label}
            </h4>
            <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              {item.value}
            </p>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#f4f4f4",
        fontFamily: "'Arial', sans-serif",
      }}
    >
      {/* Header */}
      <div
        style={{
          backgroundColor: "#2774e0",
          color: "white",
          padding: "20px",
          textAlign: "center",
          borderBottom: "3px solid #14549a",
        }}
      >
        <h1 style={{ fontSize: "2rem", margin: "0" }}>PROTECTED CAMPUS</h1>
        <h3 style={{ margin: "5px 0 0" }}>Digital Blue Light</h3>
        <h3 style={{ margin: "0" }}>Admin Data Analytics</h3>
      </div>

      {/* Scrollable Content */}
      <div
        style={{
          flex: 1,
          overflowY: "auto",
          padding: "20px",
          color: "black",
          gap: "20px",
        }}
      >
        {renderSection("Descriptive Analytics", descriptiveData, "#e8f0ff")}
        {renderSection("Predictive Analytics", predictiveData, "#f7e8ff")}
        {renderSection("Prescriptive Analytics", prescriptiveData, "#e8fff0")}
        {renderSection("Trends", trendsData, "#fff4e8")}
      </div>

      {/* Footer */}
      <div
        style={{
          textAlign: "center",
          padding: "10px 0",
          backgroundColor: "#2774e0",
          color: "white",
          borderTop: "3px solid #14549a",
        }}
      >
        <button
          onClick={() => navigate("/admin/settings")}
          style={{
            padding: "12px 25px",
            borderRadius: "12px",
            backgroundColor: "white",
            border: "none",
            color: "#2774e0",
            fontWeight: "bold",
            fontSize: "16px",
            cursor: "pointer",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          Back to Settings
        </button>
      </div>
    </div>
  );
};

export default AdminDataAnalytics;