import React from "react";

const RecordingStatus = ({ isAudioRecording }) => {
  return (
    <div
      className="recording-status"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px auto",
      }}
    >
      <div
        style={{
          backgroundColor: "#000",
          color: "#fff",
          padding: "10px 20px",
          borderRadius: "5px",
          fontSize: "16px",
          textAlign: "center",
        }}
      >
        AUDIO RECORDING: {isAudioRecording ? "ACTIVE" : "INACTIVE"}
      </div>
    </div>
  );
};

export default RecordingStatus;