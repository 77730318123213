import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "../../App.css"; // Correct path for App.css

const ResetPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate(); // Initialize navigate function

  const handleReset = () => {
    if (email) {
      alert("A password reset link has been sent to your email.");
    } else {
      alert("Please enter your email address.");
    }
  };

  const handleBack = () => {
    navigate("/"); // Navigate back to the SignInScreen
  };

  return (
    <div
      className="screen reset-password-screen"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#2774e0", // Subtle background
      }}
    >
      <div
        className="reset-container"
        style={{
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "30px 40px",
          maxWidth: "400px",
          width: "90%",
          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
          textAlign: "center",
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "10px",
            color: "#333",
          }}
        >
          Reset Password:
        </h1>
        <p
          style={{
            fontSize: "14px",
            color: "#555",
            marginBottom: "20px",
          }}
        >
          Enter your email address, and we’ll send you instructions to reset your password.
        </p>
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{
            width: "100%",
            padding: "12px",
            marginBottom: "20px",
            borderRadius: "8px",
            border: "1px solid #ccc",
            fontSize: "14px",
          }}
        />
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          <button
            onClick={handleReset}
            style={{
              flex: 1,
              padding: "12px",
              backgroundColor: "#007BFF",
              color: "white",
              border: "none",
              borderRadius: "8px",
              fontWeight: "bold",
              cursor: "pointer",
              fontSize: "16px",
            }}
          >
            Reset Password
          </button>
          <button
            onClick={handleBack}
            style={{
              flex: 1,
              padding: "12px",
              backgroundColor: "#FF5733",
              color: "white",
              border: "none",
              borderRadius: "8px",
              fontWeight: "bold",
              cursor: "pointer",
              fontSize: "16px",
            }}
          >
            Back
          </button>
        </div>
      </div>

      {/* Footer Section */}
      <p
        style={{
          color: "white",
          textAlign: "center",
          position: "fixed",
          bottom: "0",
          left: "0",
          width: "100%",
          margin: "0",
          fontSize: "14px",
          zIndex: 1000,
          backgroundColor: "transparent",
          padding: "10px 0",
        }}
      >
        Copyright © 2025 Protected Campus - All Rights Reserved.
      </p>
    </div>
  );
};

export default ResetPasswordScreen;