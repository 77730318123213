import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import '../../App.css'; // Adjust path for global styles
import AlertBox from '../../features/panic/AlertBox'; // Corrected path
import ControlBar from '../../features/panic/ControlBar'; // Corrected path
import MapSection from '../../features/panic/MapSection'; // Corrected path
import VideoSection from '../../features/panic/VideoSection'; // Corrected path
import RecordingStatus from './RecordingStatus'; // Corrected path
import SurveySection from '../../features/survey/SurveySection'; // Corrected path
const PanicScreen = ({ onCancel }) => {
  const videoRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const [startTime, setStartTime] = useState(Date.now());
  const [liveDuration, setLiveDuration] = useState("00:00");
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [loading, setLoading] = useState(true);
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const [isVideoRecording, setIsVideoRecording] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(true);
  const [alertText, setAlertText] = useState(
    "CAMPUS SECURITY HAS BEEN ALERTED AND SHARED"
  );
  const [showSurvey, setShowSurvey] = useState(false);
  const [surveyResponses, setSurveyResponses] = useState({});

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const preventFullScreen = (event) => {
        event.preventDefault();
        event.stopPropagation();
      };

      videoElement.setAttribute("playsinline", "true");
      videoElement.addEventListener("fullscreenchange", () => {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }
      });

      videoElement.addEventListener("click", preventFullScreen);
      videoElement.addEventListener("dblclick", preventFullScreen);

      return () => {
        videoElement.removeEventListener("fullscreenchange", () => {});
        videoElement.removeEventListener("click", preventFullScreen);
        videoElement.removeEventListener("dblclick", preventFullScreen);
      };
    }
  }, []);

  const startMediaStream = async () => {
    try {
      const constraints = {
        video: { facingMode: isFrontCamera ? "user" : "environment" },
        audio: true,
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
      mediaStreamRef.current = stream;

      setIsAudioRecording(
        stream.getAudioTracks().some((track) => track.enabled)
      );
      setIsVideoRecording(
        stream.getVideoTracks().some((track) => track.enabled)
      );
    } catch (error) {
      console.error("Error accessing camera or microphone:", error);
      alert(
        "Could not access the camera or microphone. Please check your permissions."
      );
    }
  };

  const stopMediaStream = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
      mediaStreamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    setIsAudioRecording(false);
    setIsVideoRecording(false);
  };

  const handleEndCall = () => {
    stopMediaStream();
    setShowSurvey(true);
  };

  const handleFlipCamera = async () => {
    stopMediaStream();
    setIsFrontCamera((prev) => !prev);
    await startMediaStream();
  };

  useEffect(() => {
    startMediaStream();
    return () => {
      stopMediaStream();
    };
  }, [isFrontCamera]);

  useEffect(() => {
    const interval = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const minutes = Math.floor(elapsed / 60000);
      const seconds = Math.floor((elapsed % 60000) / 1000);
      setLiveDuration(
        `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching location:", error);
          setLoading(false);
        }
      );
    }
  }, []);

  return (
    <div className="screen panic-screen">
      {!showSurvey ? (
        <>
          <AlertBox alertText={alertText} />
          <VideoSection
            videoRef={videoRef}
            liveDuration={liveDuration}
            onFlipCamera={handleFlipCamera}
          />
          <RecordingStatus isAudioRecording={isAudioRecording} />
          <MapSection location={location} loading={loading} />
          <ControlBar
            onInclude911={() =>
              setAlertText("CAMPUS SECURITY + 911 HAS BEEN ALERTED AND SHARED")
            }
            onEndCall={handleEndCall}
          />
        </>
      ) : (
        <SurveySection
          surveyResponses={surveyResponses}
          setSurveyResponses={setSurveyResponses}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};

export default PanicScreen;