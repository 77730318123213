import React from "react";

const AlertBox = ({ alertText }) => {
  return (
    <div
      style={{
        backgroundColor: "#2A61D6",
        color: "#fff",
        padding: "15px 20px",
        borderRadius: "8px",
        textAlign: "center",
        maxWidth: "80%",
        margin: "20px auto",
        fontSize: "18px",
        fontWeight: "bold",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
      }}
    >
      {alertText}
    </div>
  );
};

export default AlertBox;