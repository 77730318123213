import React, { useState } from 'react';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (file) {
      setUploadStatus('Uploading...');
      // Simulate file upload
      setTimeout(() => {
        setUploadStatus('File uploaded successfully!');
      }, 1500);
    } else {
      setUploadStatus('Please select a file first.');
    }
  };

  return (
    <div className="file-upload-container">
      <h2>Upload Your File</h2>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      {uploadStatus && <p className="upload-status">{uploadStatus}</p>}
    </div>
  );
};

export default FileUpload;