import React from "react";

const VideoSection = ({ videoRef, liveDuration, onFlipCamera }) => {
  return (
    <div className="video-section">
      <div className="video-overlay">
        <span className="live-indicator">
          <span className="red-dot"></span> LIVE
        </span>
        <span className="timer">{liveDuration}</span>
      </div>
      <video
        id="video"
        ref={videoRef}
        autoPlay
        muted={false}
        className="video-stream"
      ></video>
      <button className="flip-camera-button" onClick={onFlipCamera}>
        Flip Camera
      </button>
    </div>
  );
};

export default VideoSection;