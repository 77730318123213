import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const MapSection = ({ location, loading }) => {
  const blueDotIcon = new L.DivIcon({
    className: "custom-dot",
    html: `<div style="width: 12px; height: 12px; background-color: blue; border-radius: 50%;"></div>`,
  });

  return (
    <div className="map-section" style={{ marginTop: "10px" }}>
      {loading ? (
        <p>Loading map...</p>
      ) : (
        <MapContainer
          center={[location.latitude, location.longitude]}
          zoom={16}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker
            position={[location.latitude, location.longitude]}
            icon={blueDotIcon}
          />
        </MapContainer>
      )}
    </div>
  );
};

export default MapSection;