import React from "react";

const RecordingsPage = ({ setCurrentPage }) => (
  <div
    className="recordings-box"
    style={{
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "20px",
      maxWidth: "700px",
      margin: "20px auto",
      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.1)",
    }}
  >
    <h2
      style={{
        textAlign: "center",
        marginBottom: "20px",
        color: "#000",
        fontWeight: "bold",
        fontSize: "24px",
        textTransform: "uppercase",
      }}
    >
      Recordings:
    </h2>
    <p style={{ color: "#555", textAlign: "center", marginBottom: "20px" }}>
      Access and manage your recordings. Recordings include Audio, Video, and Location.
    </p>

    {/* Search and Filter */}
    <div style={{ display: "flex", marginBottom: "20px" }}>
      <input
        type="text"
        placeholder="Search recordings..."
        style={{
          flex: 1,
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          marginRight: "10px",
        }}
      />
      <select
        style={{
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      >
        <option value="all">All Types</option>
        <option value="audio">Audio</option>
        <option value="video">Video</option>
        <option value="location">Location</option>
      </select>
    </div>

    {/* Back Button */}
    <button
      onClick={() => setCurrentPage("settings")}
      style={{
        backgroundColor: "#FF5733",
        color: "white",
        borderRadius: "5px",
        padding: "10px",
        border: "none",
        cursor: "pointer",
        width: "100%",
        fontWeight: "bold",
      }}
    >
      Back to Settings
    </button>
  </div>
);

export default RecordingsPage;