import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminTheme.css';

const AdminViewAlerts = () => {
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    // Simulated API call to fetch alerts
    const mockAlerts = [
      { id: 1, location: "Main Entrance", status: "Resolved", timestamp: "2025-01-10 14:23:00" },
      { id: 2, location: "Parking Lot A", status: "Pending", timestamp: "2025-01-09 18:45:00" },
      { id: 3, location: "Library", status: "Under Review", timestamp: "2025-01-08 12:10:00" },
    ];
    setAlerts(mockAlerts);
  }, []);

  return (
    <div className="screen admin-page-container">
      <div className="header-box">
        <h1 className="header-title">VIEW ALERTS</h1>
        <p className="header-subtitle">Access and monitor all recent alerts in a detailed table format.</p>
      </div>

      <div className="excel-table-box">
        {alerts.length > 0 ? (
          <table className="excel-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Location</th>
                <th>Status</th>
                <th>Timestamp</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {alerts.map((alert) => (
                <tr key={alert.id}>
                  <td>{alert.id}</td>
                  <td>{alert.location}</td>
                  <td>
                    <span
                      className={`status-badge ${
                        alert.status === "Resolved"
                          ? "status-resolved"
                          : alert.status === "Pending"
                          ? "status-pending"
                          : "status-review"
                      }`}
                    >
                      {alert.status}
                    </span>
                  </td>
                  <td>{new Date(alert.timestamp).toLocaleString()}</td>
                  <td>
                    <button className="action-button">View Details</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="no-alerts-text">No alerts available at the moment.</p>
        )}
      </div>

      <button className="back-button" onClick={() => navigate('/admin/settings')}>
        Back to Settings
      </button>
    </div>
  );
};

export default AdminViewAlerts;