import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import "../../App.css"; // Adjust path for global styles
import SettingsPage from "../../features/settings/SettingsPage"; // Keep this as it is used
import SurveyPage from "../../features/survey/SurveyPage";
import RecordingsPage from "../../features/settings/RecordingsPage";
import ResetPasswordPage from "../../features/settings/ResetPasswordPage"; // Adjusted path to point to correct file

const SettingsScreen = () => {
  const [currentPage, setCurrentPage] = useState("settings");
  const [name, setName] = useState("John Doe");
  const [email, setEmail] = useState("johndoe@example.com");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [locationSharing, setLocationSharing] = useState(true);
  const [surveyResponses, setSurveyResponses] = useState({});
  const navigate = useNavigate(); // Initialize navigation function

  const handleSave = () => {
    alert("Settings saved successfully!");
  };

  const handleSurveyChange = (question, response) => {
    setSurveyResponses((prev) => ({ ...prev, [question]: response }));
  };

  const handleBack = () => {
    if (currentPage === "settings") {
      navigate("/home"); // Navigate back to HomeScreen
    } else {
      setCurrentPage("settings"); // Navigate back to main settings page
    }
  };

  return (
    <div className="screen settings-screen">
      {/* Render Settings Page */}
      {currentPage === "settings" && (
        <SettingsPage
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          notificationsEnabled={notificationsEnabled}
          setNotificationsEnabled={setNotificationsEnabled}
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          locationSharing={locationSharing}
          setLocationSharing={setLocationSharing}
          setCurrentPage={setCurrentPage}
          handleSave={handleSave}
          onBack={handleBack} // Pass handleBack to SettingsPage
        />
      )}

      {/* Render Survey Page */}
      {currentPage === "survey" && (
        <SurveyPage
          setCurrentPage={setCurrentPage}
          surveyResponses={surveyResponses}
          setSurveyResponses={setSurveyResponses}
          onBack={handleBack} // Pass handleBack to SurveyPage
        />
      )}

      {/* Render Recordings Page */}
      {currentPage === "recordings" && (
        <RecordingsPage setCurrentPage={setCurrentPage} onBack={handleBack} />
      )}

      {/* Render Reset Password Page */}
      {currentPage === "resetPassword" && (
        <ResetPasswordPage setCurrentPage={setCurrentPage} onBack={handleBack} />
      )}
    </div>
  );
};

export default SettingsScreen;