import React from "react";

const ToggleSettings = ({
  notificationsEnabled,
  setNotificationsEnabled,
  darkMode,
  setDarkMode,
  locationSharing,
  setLocationSharing,
}) => (
  <div>
    <div style={{ marginBottom: "15px" }}>
      <label style={{ color: "black" }}>
        <input
          type="checkbox"
          checked={notificationsEnabled}
          onChange={(e) => setNotificationsEnabled(e.target.checked)}
        />
        Enable Live Audio
      </label>
    </div>

    <div style={{ marginBottom: "15px" }}>
      <label style={{ color: "black" }}>
        <input
          type="checkbox"
          checked={darkMode}
          onChange={(e) => setDarkMode(e.target.checked)}
        />
        Enable Live Video
      </label>
    </div>

    <div style={{ marginBottom: "20px" }}>
      <label style={{ color: "black" }}>
        <input
          type="checkbox"
          checked={locationSharing}
          onChange={(e) => setLocationSharing(e.target.checked)}
        />
        Enable Live Location
      </label>
    </div>
  </div>
);

export default ToggleSettings;