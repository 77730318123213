import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import "../../App.css"; // Ensure the correct path to your global styles

const SignInScreen = ({ onSignIn }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  const handleSignIn = () => {
    if (loading) return; // Prevent multiple clicks during loading
    setLoading(true);

    setTimeout(() => {
      if (username === "ProtectedCampusAdmin" && password === "PCAdmin2025") {
        onSignIn(username, password); // Call onSignIn for admin
      } else if (username === "ProtectedCampus" && password === "PC2025") {
        onSignIn(username, password); // Call onSignIn for regular user
      } else {
        alert("Invalid username or password. Please try again.");
      }
      setLoading(false);
    }, 1000); // Simulate a short delay for login
  };

  return (
    <div className="screen login-screen">
      <div className="login-container">
        <h1 className="login-title">PROTECTED CAMPUS</h1>
        <h3 className="login-subtitle">Digital Blue Light</h3>
        <p className="login-instructions">Log in to access your safety tools.</p>
        <div className="login-form">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="input login-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input login-input"
          />
          <button
            className="button login-button"
            onClick={handleSignIn}
            disabled={loading}
          >
            {loading ? "Logging In..." : "Log In"}
          </button>
          <p
            className="forgot-password-text"
            onClick={() => navigate("/reset")}
            style={{
              cursor: "pointer",
              color: "#007BFF",
              textDecoration: "underline",
              position: "relative", // Ensure it's on top of other elements
              zIndex: 1,
            }}
          >
            Forgot Password?
          </p>
        </div>
      </div>

      {/* Footer Section */}
      <p
        style={{
          color: "white",
          textAlign: "center",
          marginTop: "30px",
        }}
      >
        Building the Future of a Protected Campus with the Power of the Digital Blue Light!
      </p>
      <p
        style={{
          color: "white",
          textAlign: "center",
          position: "fixed",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          margin: "0",
          fontSize: "14px",
          zIndex: 1000,
          padding: "5px 0",
        }}
      >
        Copyright © 2025 Protected Campus - All Rights Reserved.
      </p>
    </div>
  );
};

export default SignInScreen;