import axios from "axios";

// Backend API base URL from environment variables
const BASE_URL = process.env.REACT_APP_BACKEND_URL || "http://127.0.0.1:8000/api";

// Helper functions to get tokens from localStorage
const getAccessToken = () => localStorage.getItem("access_token");
const getRefreshToken = () => localStorage.getItem("refresh_token");

// Axios instance
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request Interceptor: Add Authorization Header
api.interceptors.request.use((config) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

// Response Interceptor: Handle Token Expiry and Refresh Automatically
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Handle 401 errors (token expired)
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      getRefreshToken()
    ) {
      originalRequest._retry = true;

      try {
        // Attempt to refresh token
        const refreshResponse = await axios.post(`${BASE_URL}/token/refresh/`, {
          refresh: getRefreshToken(),
        });

        const newAccessToken = refreshResponse.data.access;

        // Save new access token to localStorage
        localStorage.setItem("access_token", newAccessToken);

        // Retry the original request with the new access token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error("Failed to refresh token:", refreshError);

        // Redirect to login if refresh token fails
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
    }

    return Promise.reject(error);
  }
);

// Example API Endpoints (Add More as Needed)
const endpoints = {
  // Fetch alerts
  getAlerts: async () => {
    const response = await api.get("/alerts/");
    return response.data;
  },

  // Create a new alert
  createAlert: async (alertData) => {
    const response = await api.post("/alerts/", alertData);
    return response.data;
  },

  // Fetch user profile
  getUserProfile: async () => {
    const response = await api.get("/user/profile/");
    return response.data;
  },

  // Update user profile
  updateUserProfile: async (profileData) => {
    const response = await api.put("/user/update/", profileData);
    return response.data;
  },
};

export default api;
export { endpoints };