import React, { useState, useRef } from "react";
import { Link, useLocation, Outlet, useNavigate } from "react-router-dom";
import "./AdminLayout.css";

const AdminLayout = ({ onLogout }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isHolding, setIsHolding] = useState(false);
  const [holdTime, setHoldTime] = useState(0);
  const timerRef = useRef(null);

  // Check if the current route is the main admin menu
  const isAdminHome = location.pathname === "/admin";

  // Start hold timer
  const handleMouseDown = () => {
    setIsHolding(true);
    timerRef.current = setInterval(() => {
      setHoldTime((prev) => {
        if (prev >= 3) {
          clearInterval(timerRef.current); // Stop incrementing
          navigate("/admin/silent-panic-alarm"); // Redirect to admin silent panic alarm
          return 3; // Cap hold time at 3 seconds
        }
        return prev + 1;
      });
    }, 1000);
  };

  // Stop hold timer
  const handleMouseUp = () => {
    clearInterval(timerRef.current);
    setIsHolding(false);
    setHoldTime(0);
  };

  const progressWidth = `${(holdTime / 3) * 100}%`;

  return (
    <div className="admin-layout">
      <div className="content-wrapper">
        {isAdminHome && (
          <div className="main-menu">
            <header className="admin-header">
              <h1>PROTECTED CAMPUS</h1>
              <h3>Digital Blue Light</h3>
              <h3>Admin Portal</h3>
            </header>

            <main className="admin-content">
              {/* Hold Instruction */}
              <p
                style={{
                  color: "#f0f0f0",
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Hold for 3 Seconds to Activate Alarm
              </p>

              {/* Silent Panic Alarm Button */}
              <div
                className="nav-link big-button"
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                style={{
                  position: "relative",
                  backgroundColor: "#ff0000",
                  color: "white",
                  padding: "20px 40px",
                  fontSize: "1.8rem",
                  fontWeight: "bold",
                  borderRadius: "15px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    backgroundColor: "#ffffff",
                    width: isHolding ? progressWidth : "0%",
                    borderRadius: "15px",
                    transition: isHolding ? "width 1s linear" : "none",
                    zIndex: 1,
                    opacity: 0.5,
                  }}
                ></div>
                <span style={{ position: "relative", zIndex: 2 }}>
                  {isHolding
                    ? `Holding... (${Math.min(holdTime, 3)} sec)`
                    : "Silent Panic Alarm"}
                </span>
              </div>

              {/* Send Alerts Button */}
              <Link
                to="send-alerts"
                className="nav-link big-button"
                style={{
                  fontSize: "1.8rem", // Matches the font size of Silent Panic Alarm
                  fontWeight: "bold",
                  padding: "20px 40px", // Matches the button size
                  borderRadius: "15px", // Matches the rounded edges
                }}
              >
                Send Alerts
              </Link>

              <Link to="incident-reports" className="nav-link big-button">
                Incident Reports
              </Link>
              <Link to="settings" className="nav-link big-button">
                Settings
              </Link>

              <button className="logout-button" onClick={onLogout}>
                Log Out
              </button>
            </main>
          </div>
        )}

        {/* Render the child components */}
        <Outlet />
      </div>

        {/* Conditional Footer */}
      {isAdminHome && (
        <footer
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            textAlign: "center",
            padding: "10px 0",
            fontSize: "14px",
            zIndex: 1000,
            backgroundColor: "transparent",
            color: "white",
          }}
        >
          Copyright © {new Date().getFullYear()} Protected Campus - All Rights Reserved.
        </footer>
      )}
    </div>
  );
};

export default AdminLayout;