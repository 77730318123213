import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminUserManagement.css";

const AdminUserManagement = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([
    { id: 1, name: "John Doe", role: "Admin", status: "Active" },
    { id: 2, name: "Jane Smith", role: "User", status: "Inactive" },
    { id: 3, name: "Mike Brown", role: "User", status: "Active" },
  ]);

  return (
    <div className="admin-user-management">
      <div className="header-box">
        <h1>User Management</h1>
        <p>Manage all registered users, their roles, and statuses.</p>
      </div>

      <div className="user-table-container">
        <table className="user-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Role</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.name}</td>
                <td>{user.role}</td>
                <td>
                  <span
                    className={`status-badge ${
                      user.status === "Active" ? "status-active" : "status-inactive"
                    }`}
                  >
                    {user.status}
                  </span>
                </td>
                <td>
                  <button className="action-button edit">Edit</button>
                  <button className="action-button delete">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button
        className="back-button"
        onClick={() => navigate("/admin/settings")}
      >
        Back to Settings
      </button>
    </div>
  );
};

export default AdminUserManagement;