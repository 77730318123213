import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AlertList from "../components/AlertList";

const HomeScreen = ({ onLogout }) => {
  const [alerts, setAlerts] = useState([]);
  const [isHolding, setIsHolding] = useState(false);
  const [holdTime, setHoldTime] = useState(0);
  const navigate = useNavigate();
  const token = "ca3a7953605e2df8a01312e3aed8277f05c8241f"; // Your token
  const timerRef = useRef(null);

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}alerts/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        const data = await response.json();
        setAlerts(data);
      } catch (error) {
        console.error("Failed to fetch alerts:", error);
      }
    };

    fetchAlerts();
  }, []);

  const handleStartHold = () => {
    setIsHolding(true);
    timerRef.current = setInterval(() => {
      setHoldTime((prev) => {
        if (prev >= 3) {
          clearInterval(timerRef.current); // Stop timer
          navigate("/panic"); // Navigate to Panic Screen
          return 3; // Prevent holdTime from exceeding 3
        }
        return prev + 1;
      });
    }, 1000); // Increment hold time every second
  };

  const handleEndHold = () => {
    clearInterval(timerRef.current); // Clear timer when mouse/touch is released
    setIsHolding(false);
    setHoldTime(0); // Reset hold time
  };

  const progressWidth = `${(holdTime / 3) * 100}%`;

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current); // Clear timer on component unmount
    };
  }, []);

  return (
    <div className="screen home-screen">
      <div className="header-box">
        <h1>PROTECTED CAMPUS</h1>
        <h3>Digital Blue Light</h3>
      </div>

      <div className="button-container">
        {/* Hold Instruction */}
        <p
          style={{
            color: "#f0f0f0",
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Hold for 3 Seconds to Activate Alarm
        </p>

        {/* Silent Panic Alarm Button */}
        <div
          className="button-box"
          onMouseDown={handleStartHold}
          onMouseUp={handleEndHold}
          onMouseLeave={handleEndHold}
          onTouchStart={handleStartHold} // Mobile touch start
          onTouchEnd={handleEndHold} // Mobile touch end
          style={{
            position: "relative",
            backgroundColor: "#ff0000", // Red button background
            color: "white",
            padding: "20px 40px", // Larger button
            fontSize: "1.8rem", // Larger font size
            fontWeight: "bold",
            borderRadius: "15px",
            transition: "background-color 0.3s ease",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              backgroundColor: "#ffffff", // White progress bar
              width: isHolding ? progressWidth : "0%",
              borderRadius: "15px",
              transition: isHolding ? "width 1s linear" : "none",
              zIndex: 1,
              opacity: 0.5,
            }}
          ></div>
          <span style={{ position: "relative", zIndex: 2 }}>
            {isHolding ? `Holding... (${Math.min(holdTime, 3)} sec)` : "Silent Panic Alarm"}
          </span>
        </div>

        {/* Settings Button */}
        <div className="button-box" onClick={() => navigate("/settings")}>
          <h2>Settings</h2>
        </div>

        {/* Logout Button */}
        <div className="button-box logout-box" onClick={onLogout}>
          <h2>Log Out</h2>
        </div>
      </div>

      {alerts.length > 0 && (
        <div className="alert-list-container">
          <h2>Recent Alerts</h2>
          <ul className="alert-list">
            {alerts.map((alert) => (
              <li key={alert.id} className="alert-item">
                <strong>Location:</strong> {alert.location} <br />
                <strong>Status:</strong> {alert.status} <br />
                <strong>Timestamp:</strong>{" "}
                {new Date(alert.timestamp).toLocaleString()}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Footer Section */}
      <footer
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          textAlign: "center",
          padding: "10px 0",
          fontSize: "14px",
          zIndex: 1000,
          backgroundColor: "transparent",
          color: "white",
        }}
      >
        Copyright © {new Date().getFullYear()} Protected Campus - All Rights Reserved.
      </footer>
    </div>
  );
};

export default HomeScreen;