import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminIncidentReports.css";

const AdminIncidentReports = () => {
  const navigate = useNavigate();
  const [motivation, setMotivation] = useState("");

  const handleMotivationChange = (event) => {
    setMotivation(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Logic to save the report
    navigate("/admin");
  };

  const handleClose = () => {
    navigate("/admin");
  };

  return (
    <div className="incident-report-container">
      <h1>Incident Report Form</h1>
      <form onSubmit={handleSubmit}>
        {/* Individual Information */}
        <div className="form-section">
          <div className="form-group">
            <label>Individual Involved:</label>
            <input type="text" placeholder="First Name" />
            <input type="text" placeholder="Last Name" />
          </div>
          <div className="form-group">
            <label>Job Title / Role:</label>
            <input type="text" placeholder="Enter job title or role" />
          </div>
          <div className="form-group">
            <label>Phone Number:</label>
            <input type="text" placeholder="Enter phone number" />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input type="email" placeholder="Enter email" />
          </div>
          <div className="form-group">
            <label>Reported By:</label>
            <input type="text" placeholder="Enter reporter's name" />
          </div>
          <div className="form-group">
            <label>Supervisor Notified:</label>
            <input type="text" placeholder="Enter supervisor's name" />
          </div>
        </div>

        {/* Incident Details */}
        <div className="form-section">
          <h2>Incident Details</h2>
          <div className="form-group">
            <label>Date:</label>
            <input type="date" />
          </div>
          <div className="form-group">
            <label>Time:</label>
            <input type="time" />
          </div>
          <div className="form-group">
            <label>Location:</label>
            <input type="text" placeholder="Enter location" />
          </div>
          <div className="form-group">
            <label>Incident Number:</label>
            <input type="text" placeholder="Enter incident number" />
          </div>
        </div>

        {/* Possible Motivation */}
        <div className="form-section">
          <h2>Possible Motivation</h2>
          <div className="form-group">
            <label>Select Motivation:</label>
            <select
              value={motivation}
              onChange={handleMotivationChange}
              className="motivation-dropdown"
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="Obtain peer attention">Obtain peer attention</option>
              <option value="Obtain adult attention">Obtain adult attention</option>
              <option value="Obtain preferred item">Obtain preferred item</option>
              <option value="Avoid peers">Avoid peers</option>
              <option value="Avoid task">Avoid task</option>
              <option value="Avoid adult">Avoid adult</option>
              <option value="Sensory seeking">Sensory seeking</option>
              <option value="Outside school issue">Outside school issue</option>
              <option value="Student didn't understand expectations">
                Student didn't understand expectations
              </option>
              <option value="Unknown">Unknown</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>

        {/* Description */}
        <div className="form-section">
          <h2>Incident Description</h2>
          <textarea rows="4" placeholder="Provide detailed description"></textarea>
        </div>

        {/* Witnesses */}
        <div className="form-section">
          <h2>Witnesses</h2>
          <input type="text" placeholder="Witness #1 Name" />
          <input type="text" placeholder="Witness #2 Name" />
          <input type="text" placeholder="Witness #3 Name" />
        </div>

        {/* Responding Personnel */}
        <div className="form-section">
          <h2>Responding Personnel</h2>
          <div className="form-group">
            <label>Name:</label>
            <input type="text" placeholder="Enter name" />
          </div>
          <div className="form-group">
            <label>Badge Number:</label>
            <input type="text" placeholder="Enter badge number" />
          </div>
          <div className="form-group">
            <label>Arrival Time:</label>
            <input type="time" />
          </div>
          <div className="form-group">
            <label>Departure Time:</label>
            <input type="time" />
          </div>
        </div>

        {/* Actions */}
        <div className="form-actions">
          <button type="submit" className="submit-button">
            Submit Report
          </button>
          <button type="button" className="close-button" onClick={handleClose}>
            Close
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminIncidentReports;