import React from "react";
import { useNavigate } from "react-router-dom";
import "./AdminSettings.css";

const AdminSettings = () => {
  const navigate = useNavigate();

  return (
    <div className="screen admin-settings">
      <div className="header-box">
        <h1>PROTECTED CAMPUS</h1>
        <h3>Digital Blue Light</h3>
        <h3>Admin Settings</h3>
      </div>

      <div className="settings-button-container">
        <button
          className="settings-button"
          onClick={() => navigate("/admin/settings/general")}
        >
          General Settings
        </button>
        <button
          className="settings-button"
          onClick={() => navigate("/admin/settings/data-analytics")}
        >
          Data Analytics
        </button>
        <button
          className="settings-button"
          onClick={() => navigate("/admin/settings/view-alerts")}
        >
          View Alerts
        </button>
        <button
          className="settings-button"
          onClick={() => navigate("/admin/settings/user-management")}
        >
          User Management
        </button>
      </div>

      <button
        className="back-button"
        onClick={() => navigate("/admin")}
      >
        Back to Admin Menu
      </button>
    </div>
  );
};

export default AdminSettings;