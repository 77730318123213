import React from "react";

const ActionButtons = ({ setCurrentPage }) => (
  <div style={{ textAlign: "center", marginBottom: "15px" }}>
    <button
      onClick={() => setCurrentPage("survey")}
      style={{
        border: "2px solid #007BFF",
        backgroundColor: "white",
        color: "#007BFF",
        borderRadius: "5px",
        padding: "10px 20px",
        margin: "5px 0",
        width: "100%",
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      Complete Survey
    </button>
    <button
      onClick={() => setCurrentPage("recordings")}
      style={{
        border: "2px solid #28A745",
        backgroundColor: "white",
        color: "#28A745",
        borderRadius: "5px",
        padding: "10px 20px",
        margin: "5px 0",
        width: "100%",
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      View Recordings
    </button>
    <button
      onClick={() => setCurrentPage("resetPassword")}
      style={{
        border: "2px solid #FF5733",
        backgroundColor: "white",
        color: "#FF5733",
        borderRadius: "5px",
        padding: "10px 20px",
        margin: "5px 0",
        width: "100%",
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      Reset Password
    </button>
  </div>
);

export default ActionButtons;