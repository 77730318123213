import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import PanicScreen from "./features/panic/PanicScreen";
import ResetPasswordScreen from "./features/auth/ResetPasswordScreen";
import SettingsScreen from "./features/settings/SettingsScreen";
import SignInScreen from "./features/auth/SignInScreen";
import AdminLayout from "./features/admin/AdminLayout";
import AdminSilentPanicAlarm from "./features/admin/AdminSilentPanicAlarm";
import AdminSendAlerts from "./features/admin/AdminSendAlerts";
import AdminIncidentReports from "./features/admin/AdminIncidentReports";
import AdminSettings from "./features/admin/AdminSettings";
import AdminGeneralSettings from "./features/admin/AdminGeneralSettings";
import AdminDataAnalytics from "./features/admin/AdminDataAnalytics";
import AdminViewAlerts from "./features/admin/AdminViewAlerts";
import AdminUserManagement from "./features/admin/AdminUserManagement";
import FileUpload from "./components/FileUpload";
import "./App.css";

export default function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSignIn = (username, password) => {
    if (username === "ProtectedCampusAdmin" && password === "PCAdmin2025") {
      setIsAdmin(true);
      setIsLoggedIn(true);
    } else if (username === "ProtectedCampus" && password === "PC2025") {
      setIsAdmin(false);
      setIsLoggedIn(true);
    } else {
      alert("Invalid username or password. Please try again.");
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsAdmin(false);
  };

  return (
    <Router>
      <div className="App">
        <div className="main-content">
          <Routes>
            {!isLoggedIn ? (
              <>
                {/* Sign-In and Reset Password Routes */}
                <Route path="/" element={<SignInScreen onSignIn={handleSignIn} />} />
                <Route path="/reset" element={<ResetPasswordScreen />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            ) : isAdmin ? (
              <>
                {/* Admin Routes */}
                <Route path="/admin" element={<AdminLayout onLogout={handleLogout} />}>
                  <Route path="silent-panic-alarm" element={<AdminSilentPanicAlarm onCancel={() => window.history.back()} />} />
                  <Route path="send-alerts" element={<AdminSendAlerts />} />
                  <Route path="incident-reports" element={<AdminIncidentReports />} />
                  <Route path="settings" element={<AdminSettings />} />
                  <Route path="/admin/settings/general" element={<AdminGeneralSettings />} />
                  <Route path="/admin/settings/data-analytics" element={<AdminDataAnalytics />} />
                  <Route path="/admin/settings/view-alerts" element={<AdminViewAlerts />} />
                  <Route path="/admin/settings/user-management" element={<AdminUserManagement />} />
                </Route>
                <Route path="*" element={<Navigate to="/admin" />} />
              </>
            ) : (
              <>
                {/* Normal User Routes */}
                <Route path="/home" element={<HomeScreen onLogout={handleLogout} />} />
                <Route path="/panic" element={<PanicScreen onCancel={() => window.history.back()} />} />
                <Route path="/settings" element={<SettingsScreen />} />
                <Route
                  path="/upload"
                  element={
                    <div>
                      <h1>Protected Campus File Upload</h1>
                      <FileUpload />
                      <button onClick={() => window.history.back()}>Back to Home</button>
                    </div>
                  }
                />
                <Route path="*" element={<Navigate to="/home" />} />
              </>
            )}
          </Routes>
        </div>
      </div>
    </Router>
  );
}