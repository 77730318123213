import React from "react";

const SurveyPage = ({ setCurrentPage, surveyResponses, setSurveyResponses }) => {
  const handleSurveyChange = (question, response) => {
    setSurveyResponses((prev) => ({ ...prev, [question]: response }));
  };

  const handleCancelSurvey = () => {
    setCurrentPage("settings");
  };

  return (
    <div
      className="survey-section"
      style={{
        padding: "20px",
        margin: "20px auto",
        backgroundColor: "#fff",
        borderRadius: "10px",
        maxWidth: "600px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
      }}
    >
      <h2
        style={{
          color: "#000",
          fontWeight: "bold",
          textTransform: "uppercase",
        }}
      >
        Follow-Up Survey:
      </h2>
      <p style={{ marginBottom: "20px", color: "#000" }}>
        All recordings (audio, video, and location) have been downloaded to your
        settings for review and comments.
      </p>
      <div style={{ marginBottom: "15px", textAlign: "left" }}>
        <p style={{ color: "#000", fontWeight: "bold", marginBottom: "10px" }}>
          1. How would you rate the quality of the call?
        </p>
        <div>
          <label style={{ marginRight: "10px", color: "#000" }}>
            <input
              type="radio"
              name="callQuality"
              value="Excellent"
              onChange={() => handleSurveyChange("callQuality", "Excellent")}
            />
            Excellent
          </label>
          <label style={{ marginRight: "10px", color: "#000" }}>
            <input
              type="radio"
              name="callQuality"
              value="Good"
              onChange={() => handleSurveyChange("callQuality", "Good")}
            />
            Good
          </label>
          <label style={{ color: "#000" }}>
            <input
              type="radio"
              name="callQuality"
              value="Poor"
              onChange={() => handleSurveyChange("callQuality", "Poor")}
            />
            Poor
          </label>
        </div>
      </div>

      <div style={{ marginBottom: "15px", textAlign: "left" }}>
        <p style={{ color: "#000", fontWeight: "bold", marginBottom: "10px" }}>
          2. Did you find the application beneficial during your emergency?
        </p>
        <div>
          <label style={{ marginRight: "10px", color: "#000" }}>
            <input
              type="radio"
              name="responseTime"
              value="Yes"
              onChange={() => handleSurveyChange("responseTime", "Yes")}
            />
            Yes
          </label>
          <label style={{ color: "#000" }}>
            <input
              type="radio"
              name="responseTime"
              value="No"
              onChange={() => handleSurveyChange("responseTime", "No")}
            />
            No
          </label>
        </div>
      </div>

      <div style={{ marginBottom: "15px", textAlign: "left" }}>
        <p style={{ color: "#000", fontWeight: "bold", marginBottom: "10px" }}>
          3. Describe the situation that prompted you to utilize the Digital Blue Light:
        </p>
        <textarea
          name="situationDescription"
          style={{
            width: "80%",
            padding: "10px",
            borderRadius: "10px",
            border: "1px solid #ccc",
            minHeight: "80px",
            margin: "0 auto",
            display: "block",
            textAlign: "left",
            color: "#000",
          }}
          onChange={(e) =>
            handleSurveyChange("situationDescription", e.target.value)
          }
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
        <button
          onClick={() => {
            console.log("Survey Responses:", surveyResponses);
            alert("Survey Submitted!");
            setCurrentPage("settings");
          }}
          className="button complete-survey-button"
          style={{
            backgroundColor: "#4CAF50",
            color: "#fff",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          Submit Survey
        </button>
        <button
          onClick={handleCancelSurvey}
          className="button skip-survey-button"
          style={{
            backgroundColor: "#FF5733",
            color: "#fff",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SurveyPage;