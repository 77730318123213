import React from "react";

const ControlBar = ({ onInclude911, onEndCall }) => {
  return (
    <div className="control-bar">
      <button
        className="control-button include-911-button"
        onClick={onInclude911}
        style={{
          backgroundColor: "#fff",
          color: "#000",
          border: "1px solid #ddd",
          borderRadius: "5px",
          padding: "10px 20px",
          fontSize: "16px",
          cursor: "pointer",
          marginRight: "10px",
        }}
      >
        Include 911
      </button>
      <button
        className="control-button end-call-button"
        onClick={onEndCall}
        style={{
          backgroundColor: "#ff4d4d",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          padding: "10px 20px",
          fontSize: "16px",
          cursor: "pointer",
        }}
      >
        End Call
      </button>
    </div>
  );
};

export default ControlBar;