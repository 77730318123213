import React from "react";
import ProfileSettings from './ProfileSettings'; // Corrected path
import ToggleSettings from './ToggleSettings'; // Corrected path
import ActionButtons from './ActionButtons'; // Corrected path

const SettingsPage = ({
  name,
  setName,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  notificationsEnabled,
  setNotificationsEnabled,
  darkMode,
  setDarkMode,
  locationSharing,
  setLocationSharing,
  setCurrentPage,
  handleSave,
  onBack,
}) => (
  <div
    className="settings-box"
    style={{
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "20px",
      maxWidth: "400px",
      margin: "20px auto",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    }}
  >
    <h2
      style={{
        textAlign: "center",
        marginBottom: "20px",
        color: "#333",
        fontWeight: "bold",
      }}
    >
      SETTINGS:
    </h2>

    <ProfileSettings
      name={name}
      setName={setName}
      email={email}
      setEmail={setEmail}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
    />

    <ToggleSettings
      notificationsEnabled={notificationsEnabled}
      setNotificationsEnabled={setNotificationsEnabled}
      darkMode={darkMode}
      setDarkMode={setDarkMode}
      locationSharing={locationSharing}
      setLocationSharing={setLocationSharing}
    />

    <ActionButtons setCurrentPage={setCurrentPage} />

    {/* Save and Back Buttons */}
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <button
        onClick={handleSave}
        style={{
          backgroundColor: "#28A745",
          color: "white",
          border: "none",
          borderRadius: "5px",
          padding: "10px 15px",
          fontWeight: "bold",
          cursor: "pointer",
          flex: 1,
          marginRight: "5px",
        }}
      >
        Save Changes
      </button>
      <button
        onClick={onBack}
        style={{
          backgroundColor: "#FF5733",
          color: "white",
          border: "none",
          borderRadius: "5px",
          padding: "10px 15px",
          fontWeight: "bold",
          cursor: "pointer",
          flex: 1,
          marginLeft: "5px",
        }}
      >
        Back
      </button>
    </div>
  </div>
);

export default SettingsPage;